@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?6536dd102fec42e0f685214b8b238eb4?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?6536dd102fec42e0f685214b8b238eb4") format("woff2"),
url("./cy-custom-icons.woff?6536dd102fec42e0f685214b8b238eb4") format("woff"),
url("./cy-custom-icons.ttf?6536dd102fec42e0f685214b8b238eb4") format("truetype"),
url("./cy-custom-icons.svg?6536dd102fec42e0f685214b8b238eb4#cy-custom-icons") format("svg");
}

i.icon.circle-check,
i.icon.circle-pause,
i.icon.circle-progress,
i.icon.cy,
i.icon.dolly-filled,
i.icon.exact,
i.icon.handyman,
i.icon.local_atm_white,
i.icon.move_to_inbox_white,
i.icon.mui-power-off,
i.icon.mui-power-on,
i.icon.navision,
i.icon.nutrition,
i.icon.outbox_white,
i.icon.script-type-light,
i.icon.swap_horizontal_circle_white,
i.icon.tank-storage,
i.icon.unit4 {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.circle-check:before {
    content: "\f101";
}
i.icon.circle-pause:before {
    content: "\f102";
}
i.icon.circle-progress:before {
    content: "\f103";
}
i.icon.cy:before {
    content: "\f104";
}
i.icon.dolly-filled:before {
    content: "\f105";
}
i.icon.exact:before {
    content: "\f106";
}
i.icon.handyman:before {
    content: "\f107";
}
i.icon.local_atm_white:before {
    content: "\f108";
}
i.icon.move_to_inbox_white:before {
    content: "\f109";
}
i.icon.mui-power-off:before {
    content: "\f10a";
}
i.icon.mui-power-on:before {
    content: "\f10b";
}
i.icon.navision:before {
    content: "\f10c";
}
i.icon.nutrition:before {
    content: "\f10d";
}
i.icon.outbox_white:before {
    content: "\f10e";
}
i.icon.script-type-light:before {
    content: "\f10f";
}
i.icon.swap_horizontal_circle_white:before {
    content: "\f110";
}
i.icon.tank-storage:before {
    content: "\f111";
}
i.icon.unit4:before {
    content: "\f112";
}
