i.icon.voicemail:after { content: '\F897'; }
i.icon.pen:after { content: '\F304'; }
i.icon.th-large:after { content: '\F009'; }
i.icon.link:after { content: '\F0C1'; }
i.icon.hammer:after { content: '\F6E3'; }
i.icon.tools:after { content: '\F7D9'; }
i.icon.divide:after { content: '\F529'; }
i.icon.equals:after { content: '\F52C'; }
i.icon.less-than:after { content: '\F536'; }
i.icon.greater-than:after { content: '\F531'; }
i.icon.water:after { content: '\F773'; }
i.icon.wind:after { content: '\F72E'; }
i.icon.seedling:after { content: '\F4D8'; }
i.icon.tally:after { content: '\F69C'; }
i.icon.comment-alt-exclamation:after { content: '\F4A5'; }
i.icon.calendar-day:after { content: '\F783'; }
i.icon.faucet:after { content: '\E005'; }
i.icon.broom:after { content: '\f51a'; }
i.icon.th-3x3:after { content: '\f00a'; }
i.icon.box-open:after { content: '\f49e'; }
i.icon.clipboard-check:after { content: '\f46c'; }
i.icon.shapes:after { content: '\f61f'; }
i.icon.box-check:after { content: '\f467'; }
i.icon.shipping-fast:after { content: '\f48b'; }
i.icon.file-invoice-dollar:after { content: '\f571'; }
i.icon.arrow-from-bottom:after { content: '\f342'; }
i.icon.signal-stream:after { content: '\f8dd'; }
i.icon.layer-group::after { content: '\f5fd'; }
i.icon.battery.bolt:before { content: '\f376'; }
i.icon.barcode.read:before { content: '\f464'; }
